import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

//LIST popup
export const getPopUpList = createAsyncThunk('popup/getPopUp', async () => {
  const params = {
    by: 'def.id',
    sort: 'desc',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/apps/popup/select?`, config)

  return response.data.data.list_popup
})

// DETAIL
export const detailPopup = createAsyncThunk('popup/detail', async (params) => {
  const response = await axios
    .get(`${URL_API}/v1/api/apps/popup/id/` + params.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error detail')
    })

  return response.data.data
})

//DELETE
export const deletePopup = createAsyncThunk('popup/Delete', async (data) => {
  const response = await axios
    .delete(`${URL_API}/v1/api/apps/popup/id/` + data.id, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      // console.log(err, 'error')
    })

  return response.data.data.id
})

const popupEntity = createEntityAdapter({
  selectId: (popup) => popup.id,
})

const popupSlice = createSlice({
  name: 'popup',
  initialState: popupEntity.getInitialState(),
  extraReducers: {
    [getPopUpList.fulfilled]: (state, action) => {
      popupEntity.setAll(state, action.payload)
    },
    [detailPopup.fulfilled]: (state, action) => {
      popupEntity.setOne(state, action.payload)
    },
    [deletePopup.fulfilled]: (state, action) => {
      popupEntity.removeOne(state, action.payload)
    },
  },
})

export const popupSelector = popupEntity.getSelectors((state) => state.popup)
export default popupSlice.reducer
