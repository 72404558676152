import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { URL_API } from '../../config/enviroment'

// LIST TABLE TOKO
export const getCustomers = createAsyncThunk('customers/getCustomers', async (parameter) => {
  const params = {
    by: 'c.customer_name',
    sort: 'desc',
    admin_user_id: localStorage.getItem('user_id'),
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/customer/?by=c.created_date`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer == null ? [] : response.data.data.list_customer,
    meta: response.data.meta,
  }
  //console.log('resp data: ', response)
  return responseData
})

// LIST TABLE TOKO Max Poin Custom
export const getCustomersMaxPoin = createAsyncThunk('customers/getCustomers', async (parameter) => {
  const params = {
    by: 'c.customer_name',
    sort: 'desc',
    admin_user_id: localStorage.getItem('user_id'),
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    monthly_max_point: '1',
  }

  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }

  const response = await axios.get(`${URL_API}/v1/api/web/customer/?by=c.created_date`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer == null ? [] : response.data.data.list_customer,
    meta: response.data.meta,
  }
  // console.log('resp data: ', response)
  return responseData
})

// LIST TABLE TOKO OPTION
export const getCustomersOption = createAsyncThunk('customers/getCustomers', async (parameter) => {
  const params = {
    by: 'c.id',
    admin_user_id: localStorage.getItem('user_id'),
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    branch_id: parameter.branch_id,
    show_in_app: parameter.show_in_app,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/customer/?by=c.created_date`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer == null ? [] : response.data.data.list_customer,
    meta: response.data.meta,
  }
  //console.log('resp data: ', response.data.data.list_customer)
  return responseData
})

// DETAIL TOKO
export const getDetailCust = createAsyncThunk('customers/getCustomersDetail', async (params) => {
  console.log('API detail toko hit')
  const response = await axios.get(`${URL_API}/v1/api/web/customer/id/` + params.customer_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
  return response.data.data
})

export const getTarget = createAsyncThunk('customers/getTarget', async (params) => {
  console.log('API target sfa hit')
  const response = await axios.get(`${URL_API}/v1/api/web/customer/id/` + params.customer_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
  //console.log('resp data: ', response.data.data.customer_target)
  return response.data.data.customer_target
})

export const getAchiev = createAsyncThunk('customers/getAchieve', async (params) => {
  console.log('API achieve hit')
  const response = await axios.get(`${URL_API}/v1/api/web/customer/id/` + params.customer_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
  //console.log('resp data: ', response.data.data.customer_achievement)
  return response.data.data.customer_achievement
})

export const getSchedule = createAsyncThunk('customers/getSchedule', async (params) => {
  console.log('API schedule hit')
  const response = await axios.get(`${URL_API}/v1/api/web/customer/id/` + params.customer_id, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
  //console.log('resp data: ', response.data.data.salesman_visit)
  return response.data.data.salesman_visit
})

//SEARCH BY NAME
export const getSearch = createAsyncThunk('customers/getSearch', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    // by: 'c.customer_name',
    admin_user_id: localStorage.getItem('user_id'),
    search: parameter.search,
    branch_id: parameter.branch_id,
    phone_number: parameter.phone_number,
    show_in_app: parameter.show_in_app,
    sort: parameter.sort,
    by: `c.${parameter.by || 'id'}`,
    customer_type_id: parameter.customer_type_id,
    salesman_type_id: parameter.salesman_type_id,
    is_data_complete: parameter.is_data_complete,
    active: parameter.active,
    admin_validate: parameter.admin_validate,
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/customer/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer == null ? [] : response.data.data.list_customer,
    meta: response.data.meta,
  }
  //console.log('search toko:', response)
  return responseData
})

//SEARCH BY NAME MAX POIN
export const getSearchMaxPoin = createAsyncThunk('customers/getSearch', async (parameter) => {
  const params = {
    page: parameter?.page || 1,
    limit: parameter?.limit || 10,
    // by: 'c.customer_name',
    admin_user_id: localStorage.getItem('user_id'),
    search: parameter.search,
    branch_id: parameter.branch_id,
    phone_number: parameter.phone_number,
    show_in_app: parameter.show_in_app,
    sort: parameter.sort,
    by: `c.${parameter.by || 'id'}`,
    customer_type_id: parameter.customer_type_id,
    salesman_type_id: parameter.salesman_type_id,
    is_data_complete: parameter.is_data_complete,
    active: parameter.active,
    admin_validate: parameter.admin_validate,
    monthly_max_point: '1',
  }
  const config = {
    params: params,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  }
  const response = await axios.get(`${URL_API}/v1/api/web/customer/?`, config)
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer == null ? [] : response.data.data.list_customer,
    meta: response.data.meta,
  }
  //console.log('search toko:', response)
  return responseData
})

//UPDATE TOKO
export const updateToko = createAsyncThunk('customer/update', async ({ formdata }) => {
  //console.log('formdata:', formdata)
  let formObject = Object.fromEntries(formdata.entries())
  let formdatavalue = formObject.form_data
  let formData = JSON.parse(formdatavalue)

  const response = await axios
    .put(`${URL_API}/v1/api/web/customer/id/` + formData.id, formdata, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })

    .catch((err) => {
      //console.log(err.response.data.messages.err_msg[0])
    })
  //console.log(response, 'res update')
  // get 2 data
  const responseData = {
    data: response.data.data.list_customer,
    meta: response.data.meta,
  }
  return responseData
})

//UPDATE BULK
//ADD
export const updateBulk = createAsyncThunk('customer/update-bulk', async ({ formdata }) => {
  // console.log('formdata:', formdata)

  const response = await axios
    .put(`${URL_API}/v1/api/web/customer/edit/bulk`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

//UPDATE MAX POINT
export const updateMaxPoint = createAsyncThunk(
  'customer/update-max-point',
  async ({ formdata }) => {
    // console.log('formdata:', formdata)

    const response = await axios
      .put(`${URL_API}/v1/api/web/customer/edit/max_point`, formdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .catch((err) => {
        console.log(err, 'error add')
      })

    return response.data.data
  },
)

//UPDATE CLASS MEKAR
export const updateClassMekar = createAsyncThunk(
  'customer/update-class-mekar',
  async ({ formdata }) => {
    console.log('formdata:', formdata)

    const response = await axios
      .put(`${URL_API}/v1/api/web/customer/edit/bulk/class_mekar`, formdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .catch((err) => {
        console.log(err, 'error add')
      })

    return response.data.data
  },
)

//UPDATE EXCLUDE POINT
export const updatePoinExclude = createAsyncThunk(
  'customer/update-exclude-poin',
  async ({ formdata }) => {
    console.log('formdata:', formdata)

    const response = await axios
      .put(`${URL_API}/v1/api/web/customer/edit/bulk/exclude_all_point`, formdata, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .catch((err) => {
        console.log(err, 'error add')
      })

    return response.data.data
  },
)

//UPDATE NOTE
export const updateNote = createAsyncThunk('customer/update-note', async ({ formdata }) => {
  console.log('formdata:', formdata)

  const response = await axios
    .put(`${URL_API}/v1/api/web/customer/edit/note`, formdata, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .catch((err) => {
      console.log(err, 'error add')
    })

  return response.data.data
})

const customerEntity = createEntityAdapter({
  selectId: (customer) => customer.customer_id,
})

const customerSlice = createSlice({
  name: 'customer',
  initialState: customerEntity.getInitialState(),
  extraReducers: {
    [getCustomers.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      customerEntity.setAll(state, action.payload)
    },
    [getCustomersMaxPoin.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      customerEntity.setAll(state, action.payload)
    },
    [getCustomersOption.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      customerEntity.setAll(state, action.payload)
    },

    [getDetailCust.fulfilled]: (state, action) => {
      customerEntity.setAll(state, action.payload)
    },

    [getTarget.fulfilled]: (state, action) => {
      customerEntity.setAll(state, action.payload)
    },
    [getAchiev.fulfilled]: (state, action) => {
      customerEntity.setAll(state, action.payload)
    },
    [getSchedule.fulfilled]: (state, action) => {
      customerEntity.setAll(state, action.payload)
    },
    [getSearch.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      customerEntity.setAll(state, action.payload)
    },
    [getSearchMaxPoin.fulfilled]: (state, action) => {
      //get 2 data
      state.data = action.payload.data
      state.meta = action.payload.meta
      customerEntity.setAll(state, action.payload)
    },

    [updateToko.fulfilled]: (state, action) => {
      customerEntity.updateOne(state, {
        customer_id: action.payload.customer_id,
        updates: action.payload,
      })
    },
    [updateBulk.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        customerEntity.addOne(state, action.payload)
      }
    },
    [updateMaxPoint.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        customerEntity.addOne(state, action.payload)
      }
    },
    [updateClassMekar.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        customerEntity.addOne(state, action.payload)
      }
    },
    [updatePoinExclude.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        customerEntity.addOne(state, action.payload)
      }
    },
    [updateNote.fulfilled]: (state, action) => {
      if (action.payload && action.payload.id) {
        customerEntity.addOne(state, action.payload)
      }
    },
  },
})

export const customerSelector = customerEntity.getSelectors((state) => state.customer)
export const selectCustomer = (state) => state.customer
export default customerSlice.reducer
